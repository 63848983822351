exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-hotel-tsx": () => import("./../../../src/pages/hotel.tsx" /* webpackChunkName: "component---src-pages-hotel-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-shop-cart-tsx": () => import("./../../../src/pages/shop/cart.tsx" /* webpackChunkName: "component---src-pages-shop-cart-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-sponsors-tsx": () => import("./../../../src/pages/sponsors.tsx" /* webpackChunkName: "component---src-pages-sponsors-tsx" */),
  "component---src-templates-schedule-day-template-tsx": () => import("./../../../src/templates/scheduleDayTemplate.tsx" /* webpackChunkName: "component---src-templates-schedule-day-template-tsx" */),
  "component---src-templates-schedule-event-template-tsx": () => import("./../../../src/templates/scheduleEventTemplate.tsx" /* webpackChunkName: "component---src-templates-schedule-event-template-tsx" */),
  "component---src-templates-shop-product-template-tsx": () => import("./../../../src/templates/shopProductTemplate.tsx" /* webpackChunkName: "component---src-templates-shop-product-template-tsx" */)
}

